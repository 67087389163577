ol {
  counter-reset: section;
}
ol > li {
  counter-increment: section;
}
ol > li > ol {
  counter-reset: subsection;
}
ol > li > ol > li {
  counter-increment: subsection;
}
ol > li > ol > li::marker {
  content: counter(section) "." counter(subsection) ". ";
}
ol > li > ol > li > ol {
  counter-reset: subsubsection;
}
ol > li > ol > li > ol > li {
  counter-increment: subsubsection;
}
ol > li > ol > li > ol > li::marker {
  content: counter(section) "." counter(subsection) "." counter(subsubsection)
    ". ";
}
